import { activeArray } from '@/utils/localStorage.js'
// 表单项配置
let formData = [
  {
    items: {
      name: {
        label: '关键字',
        type: 'text',
        clearable: true
      }
    }
  }
]

// 新增表单配置
let addForm = [
  {
    showbutton: true,
    slotArr: ['role'],
    items: {
      loginName: {
        label: '用户名',
        type: 'text',
        maxLength: 12,
        placeholder: '请输入',
        clearable: true,
        rules: [
          { required: true, message: '请输入用户名' },
          {
            validator: function (rule, value, callback) {
              var specialKey = /^[\u4E00-\u9FA5A-Za-z0-9]+$/
              if (value && specialKey.test(value) && value.length > 3 && value.length < 13) {
                return callback()
              } else {
                return callback(new Error('只能输入长度为4~12字符的中文、英文、数字但不包含特殊字符'))
              }
            }
          }
        ]
      },
      password: {
        label: '密码',
        type: 'password',
        maxLength: 20,
        placeholder: '请输入',
        rules: [
          { required: true, message: '请输入密码' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符' }
        ]
      },
      role: {
        label: '角色',
        name: 'role',
        // type: 'selectMore',
        type: 'slot',
        children: [
          {
            label: '管理员',
            value: '1',
            checked: true
          },
          {
            label: '普通角色',
            value: '2'
          }
        ]
      }
    }
  }
]

// 编辑表单配置
let editForm = [
  {
    showbutton: true,
    slotArr: ['role'],
    items: {
      loginName: {
        label: '用户名',
        type: 'text',
        maxLength: 12,
        disabled: true,
        rules: [
          { required: true, message: '请输入用户名' },
          // { min: 4, max: 12, message: '长度在 4 到 12 个字符' },
          {
            validator: function (rule, value, callback) {
              var specialKey = /^[\u4E00-\u9FA5A-Za-z0-9]+$/
              if (value && specialKey.test(value) && value.length > 3 && value.length < 13) {
                return callback()
              } else {
                return callback(new Error('只能输入长度为4~12字符的中文、英文、数字但不包含特殊字符'))
              }
            }
          }
        ]
      },
      // phone: {
      //   label: '电话',
      //   type: 'text'
      // },
      role: {
        label: '角色',
        name: 'role',
        // type: 'selectMore',
        type: 'slot',
        children: [
          {
            label: '管理员',
            value: '1',
            checked: true
          },
          {
            label: '普通角色',
            value: '2'
          }
        ]
      }
    }
  }
]

// 重置密码
let resetPasswordForm = [
  {
    showbutton: true,
    slotArr: ['resetPassword'],
    items: {
      userTree: {
        type: 'slot',
        name: 'resetPassword'
      }
    }
  }
]

// 复制新密码
let newPasswordForm = [
  {
    showbutton: true,
    slotArr: ['newPassword'],
    items: {
      userTree: {
        label: '密码',
        type: 'slot',
        name: 'newPassword'
      }
    }
  }
]

// 表头图标配置
let headData = {
  title: '用户管理',
  items: [
    /* 新增按钮 */
    {
      style: 'button',
      label: '新增',
      icon: 'el-icon-plus',
      size: 'medium',
      type: 'primary',
      // 控制按钮显示隐藏
      showFilter: (item, thisVue) => {
        return activeArray('/account/account-add')
      },
      clickEvent: (thisVue) => {
        thisVue.dialogForm = thisVue.tableObj.addForm
        thisVue.dialogValue = {}
        thisVue.roleValue = []
        // 清除点编辑之后清空表单出现的校验提示
        if (thisVue.$refs.addForm) {
          thisVue.$refs.addForm.$refs.form.resetFields()
        }
        thisVue.dialogTitle = '新增成员'
        thisVue.$set(thisVue, 'dialogShow', true)
      }
    }
  ]
}

// 列表columns配置
let columnsData = [
  {
    label: '序号',
    type: 'index',
    align: 'center',
    show: true,
    width: 100
  },
  {
    label: '用户名',
    prop: 'loginName',
    show: true
  },
  {
    label: '创建时间',
    prop: 'createTime',
    show: true,
    enableSlot: true
  },
  {
    label: '操作',
    prop: 'operators',
    align: 'right',
    width: 300,
    fixed: 'right',
    children: {
      edit: {
        label: '编辑',
        icon: 'ym-icon-bianji',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          return activeArray('/account/account-update')
          // return false
        },
        clickEvent: (thisVue, row) => {
          thisVue.dialogForm = thisVue.tableObj.editForm
          thisVue.dialogValue = row
          thisVue.dialogTitle = '编辑 ' + row.loginName
          thisVue.getAccount(row.accountID)
          thisVue.getAccountRoles(row.accountID)
          thisVue.$set(thisVue, 'dialogShow', true)
        }
      },
      delete: {
        label: '删除',
        icon: 'ym-icon-shanchu',
        color: '#FF8851',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          return activeArray('/account/account-delete-(\\d+)')
        },
        clickEvent: (thisVue, row) => {
          thisVue.deleteRow(row)
        }
      },
      password: {
        label: '重置密码',
        icon: 'ym-icon-liulan',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          return activeArray('/account/account-reset-password')
        },
        clickEvent: (thisVue, row) => {
          thisVue.dialogValue = row
          thisVue.dialogTitle = '重置密码'
          // thisVue.dialogContent = 'resetPassword'
          thisVue.$set(thisVue, 'passwordDialogShow', true)
        }
      }
    }
  }
]

// 分页配置项
let pageData = {
  size: 10, // 每页的数据量
  pageSize: 10, // 每页的条数跳转页面
  pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
  total: 0, // 数据总量
  layout: 'prev, pager, next, total, sizes, jumper, slot',
  currentPage: 1 // 当前页
}

let tableObj = {
  'columnsData': columnsData, // 表格列/操作列配置
  'formData': formData, // 搜索表单项
  'headData': headData, // 表头图标配置
  'addForm': addForm, // 新增表单配置
  'editForm': editForm, // 编辑表单配置
  'resetPasswordForm': resetPasswordForm, // 重置密码
  'newPasswordForm': newPasswordForm, // 新密码
  'pageData': pageData // 分页数据
}

export default tableObj
